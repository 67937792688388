@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400&display=swap');



$font-family-base: 'Inter', 'Helvetica Neue', Arial, 'Lucida Grande', sans-serif;
$font-size-base: 0.9375rem;
$font-weight-normal: 400;

// HEADINGS

$headings-font-family:        'Raleway', 'Helvetica Neue', Arial, 'Lucida Grande', sans-serif;
$headings-font-style:         null;
$headings-font-weight:        400;
$headings-color:              inherit;

$heading-size: 1.5;
$h1-font-size:                $font-size-base * $heading-size * 3.1;
$h2-font-size:                $font-size-base * $heading-size * 1.7;
$h3-font-size:                $font-size-base * $heading-size * 1.4;
$h4-font-size:                $font-size-base * $heading-size * 1.2;
$h5-font-size:                $font-size-base * $heading-size * 1.1;
$h6-font-size:                $font-size-base * $heading-size;

// DISPLAY FONTS

$display-font-family:        'Raleway', 'Helvetica Neue', Arial, 'Lucida Grande', sans-serif;
$display-font-weight: 200;
$display-size: 2.5 ;

$display-font-sizes: (
  1: $font-size-base * $display-size * 2.1,
  2: $font-size-base * $display-size * 1.7,
  3: $font-size-base * $display-size * 1.4,
  4: $font-size-base * $display-size * 1.2,
  5: $font-size-base * $display-size * 1.1,
  6: $font-size-base * $display-size ,
);

// COLORS

$primary: #36302D; // UPDATED
$secondary: #b16139; 
$info: #EFF0F2;

$success:#45736A; 
$warning:#D9C116;
$danger:#D4164B;

$light: #dedede; // UPDATED
$gray: #8c8686; 
$disabled: #6C757D;
$dark: #201a16;  // UPDATED

$component-active-bg: $success;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "gray":       $gray,
  "disabled":   $disabled,
  "dark":       $dark,
  "white":      #ffffff
);

$input-focus-box-shadow: 0;
$enable-negative-margins: true;
$font-weight-bold: 700;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,

  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
  9: $spacer * 9,
  10: $spacer * 10.5
);

// BUTTONS

$btn-font-family: $headings-font-family;
$btn-font-size: 13px;
$btn-font-size-lg: 16px;
$btn-font-weight: 400;
$btn-border-width: 2px;
$btn-padding-x-sm: 1em;
$btn-padding-y-sm: 0.5em;
$btn-padding-x: 1.1em;
$btn-padding-y: 0.7em;
$btn-padding-x-lg: 1.2em;
$btn-padding-y-lg: 0.6em;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

@import '../node_modules/bootstrap/scss/bootstrap.scss';

// DEFAULT

body {
  -webkit-font-smoothing: antialiased;
}

// CUSTOM

// -- Buttons

.btn {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.full-page {
    min-height: 100svh;
}

.logo {
    max-width: 3rem;
}


// EXKEA

// -- Hero-banners

.bg-overlay {
  position: relative;
  z-index: 2;
}

.bg-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlaycolor);
  opacity: var(--overlayopacity);
  z-index: -1;
}

@media (min-width: 992px) {
.navbar-transparent {
    background-color: rgba(0, 0, 0, .0) !important;
}}
.navbar-transparent {
    background-color: #201a16;
}

.nav-link-active {
    text-decoration: underline;
}
.nav-link:hover {
    text-decoration: underline;
}

.min-size-xs {
  min-height: calc(1 / 4 * 100vw);
}
.min-size-sm {
  min-height: calc(9 / 21 * 100vw);
}
.min-size-md {
  min-height: calc(9 / 16 * 100vw);
}
.min-size-lg {
  min-height: calc(1 / 1 * 100vw);
}

.hero-banner-fixed-xs {
  --bs-aspect-ratio: calc(1 / 4 * 100%);
  @include media-breakpoint-down(lg) { 
    --bs-aspect-ratio: calc(1 / 4 * 125%)
  }
  @include media-breakpoint-down(md) { 
    --bs-aspect-ratio: calc(1 / 4 * 180%)
  }
  @include media-breakpoint-down(sm) { 
    --bs-aspect-ratio: calc(1 / 4 * 250%)
  }
}

.hero-banner-fixed-sm {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
  @include media-breakpoint-down(lg) { 
    --bs-aspect-ratio: calc(9 / 21 * 125%)
  }
  @include media-breakpoint-down(md) { 
    --bs-aspect-ratio: calc(9 / 21 * 180%)
  }
  @include media-breakpoint-down(sm) { 
    --bs-aspect-ratio: calc(9 / 21 * 250%)
  }
}

.hero-banner-fixed-md {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
  @include media-breakpoint-down(lg) { 
    --bs-aspect-ratio: calc(9 / 16 * 125%)
  }
  @include media-breakpoint-down(md) { 
    --bs-aspect-ratio: calc(9 / 16 * 180%)
  }
  @include media-breakpoint-down(sm) { 
    --bs-aspect-ratio: calc(9 / 16 * 250%)
  }
}

.hero-banner-fixed-lg {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
  @include media-breakpoint-down(lg) { 
    --bs-aspect-ratio: calc(3 / 4 * 125%)
  }
  @include media-breakpoint-down(md) { 
    --bs-aspect-ratio: calc(3 / 4 * 180%)
  }
  @include media-breakpoint-down(sm) { 
    --bs-aspect-ratio: calc(3 / 4 * 250%)
  }
}

// Footer

.footer a {
  color: inherit;
  text-decoration: none;
}